exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-about-section-js": () => import("./../../../src/pages/home/aboutSection.js" /* webpackChunkName: "component---src-pages-home-about-section-js" */),
  "component---src-pages-home-footer-js": () => import("./../../../src/pages/home/footer.js" /* webpackChunkName: "component---src-pages-home-footer-js" */),
  "component---src-pages-home-help-section-js": () => import("./../../../src/pages/home/helpSection.js" /* webpackChunkName: "component---src-pages-home-help-section-js" */),
  "component---src-pages-home-hero-section-2-js": () => import("./../../../src/pages/home/heroSection2.js" /* webpackChunkName: "component---src-pages-home-hero-section-2-js" */),
  "component---src-pages-home-hero-section-js": () => import("./../../../src/pages/home/heroSection.js" /* webpackChunkName: "component---src-pages-home-hero-section-js" */),
  "component---src-pages-home-learn-section-js": () => import("./../../../src/pages/home/learnSection.js" /* webpackChunkName: "component---src-pages-home-learn-section-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

